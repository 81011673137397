import React from 'react';
import { formatNumber } from 'utils/formatNumber';

import './User.scss';

interface IUserProps {
  photoUrl: string;

  username: string;

  wallet?: string;
  isShowWallet?: boolean;

  ticketsAmount?: number;
  isShowTickets?: boolean;
  onWalletClick?: () => void;
}

const User: React.FC<IUserProps> = ({
  photoUrl,
  username,
  isShowTickets,
  ticketsAmount,
  isShowWallet,
  wallet,
  onWalletClick,
}) => (
  <div className="user">
    <div className="user-img">
      {photoUrl ? <img src={photoUrl} alt={username?.[0] || ''} /> : username?.[0] || ''}
    </div>
    <div className="user-info">
      <h4>{username}</h4>
      {isShowTickets && (
        <p className="user-tickets">
          <img src="images/point.png" alt="Points" loading="lazy" />
          <span>{formatNumber(ticketsAmount)}</span>
        </p>
      )}
      {isShowWallet && wallet && (
        <p className="user-wallet" onClick={onWalletClick}>
          <img src="images/hot.png" alt="Hot" loading="lazy" />
          <span>{wallet}</span>
        </p>
      )}
    </div>
  </div>
);

export default React.memo(User);
