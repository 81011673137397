import React, { useEffect, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { NavLink, useNavigate } from 'react-router-dom';
import { changeTaskStatus, getTasksList } from 'api/tasks';
import { ETaskStatus, ITaskProgress } from 'interface/task';
import useMainStore from 'store/main.store';
import { EUserRoles } from 'interface/user';

import './AdminDaily.scss';

const PAGE_SIZE = 10;

const AdminDaily: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useMainStore();
  const [isCopied, setIsCopied] = useState<Record<string, boolean>>({});
  const [isAction, setIsAction] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { data, size, setSize, isValidating, mutate } = useSWRInfinite<ITaskProgress>(
    (index) => `daily-tasks-list?page=${index}`,
    // @ts-ignore
    () => getTasksList(size, PAGE_SIZE),
  );

  const tasks = data ? data.flat() : [];

  const hasMoreTasks = tasks.length === PAGE_SIZE * size;

  const loadMoreTasks = async () => {
    setIsLoadingMore(true);
    await setSize(size + 1);
    setIsLoadingMore(false);
  };

  const onApprove = async (id: string) => {
    setIsAction(true);
    await changeTaskStatus(id, ETaskStatus.COMPLETED_CLAIMED)
      .then(() => mutate())
      .finally(() => setIsAction(false));
  };

  const onReject = async (id: string) => {
    setIsAction(true);
    await changeTaskStatus(id, ETaskStatus.VERIFY_REJECTED)
      .then(() => mutate())
      .finally(() => setIsAction(false));
  };

  useEffect(() => {
    if (!user || user.role === EUserRoles.USER) {
      navigate('/admin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onCopy = (id: string) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        setIsCopied((p) => ({ ...p, [id]: true }));
        setTimeout(() => {
          setIsCopied((p) => ({ ...p, [id]: false }));
        }, 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="daily-tasks-page">
      <NavLink className="back-link" to="/admin">
        ← Back
      </NavLink>
      <h2>Daily tasks</h2>
      <ul className="tasks-list">
        {tasks?.map((task) => (
          <li key={task.id} className="task-item">
            <div className="task-details">
              <p className="task-id">
                <span>
                  Task Id: <b>{task.id}</b>
                </span>
                <img
                  onClick={() => onCopy(task.id)}
                  src={`images/copy-icon${isCopied[task.id] ? '-success' : '-inverted'}.png`}
                  alt=""
                />
              </p>
              <p className="task-title">{task.template.title}</p>
              <p className="task-proof">
                Proof:{' '}
                <a href={task.proof} target="blank" rel="noopener noreferrer">
                  {task.proof}
                </a>
              </p>
            </div>
            <div className="task-actions">
              <button
                className="btn approve"
                onClick={() => onApprove(task.id)}
                disabled={isAction}>
                Verify
              </button>
              <button className="btn reject" onClick={() => onReject(task.id)} disabled={isAction}>
                Reject
              </button>
            </div>
          </li>
        ))}
        {!tasks?.length && !isValidating && <p className="no-items">No daily tasks yet.</p>}
      </ul>

      {hasMoreTasks && (
        <div className="load-more-container">
          <button className="btn load-more" onClick={loadMoreTasks} disabled={isLoadingMore}>
            {isLoadingMore ? 'Loading...' : 'Load more'}
          </button>
        </div>
      )}

      {!hasMoreTasks && !isValidating && <p className="all-tasks-loaded">All tasks loaded.</p>}
    </div>
  );
};

export default AdminDaily;
