import { create } from 'zustand';
import { ENFT_GRADE, INFTToken } from 'interface/nft';

interface INFTsStore {
  nfts: Map<string, INFTToken>;
  grades: Map<string, ENFT_GRADE>;
  bonuses: Map<string, boolean>;
  loading: boolean;

  setNFT: (nft: INFTToken) => void;
  setNFTs: (nfts: INFTToken[]) => void;
  setGrade: (tokenId: string, level: ENFT_GRADE) => void;
  setGrades: (levels: Map<string, ENFT_GRADE>) => void;
  setBonus: (tokenId: string, bonus: boolean) => void;
  setBonuses: (bonuses: Map<string, boolean>) => void;
  setLoading: (loading: boolean) => void;

  getGradeById: (tokenId: string) => ENFT_GRADE;
  getNFTById: (tokenId: string) => INFTToken;
  getIsLoading: () => boolean;
}

const useNFTsStore = create<INFTsStore>((set, get) => ({
  nfts: new Map(),
  grades: new Map(),
  bonuses: new Map(),
  loading: false,

  setNFT: (nft: INFTToken) =>
    set((state) => {
      state.nfts.set(nft.token_id, nft);
      return state;
    }),
  setNFTs: (nfts: INFTToken[]) =>
    set((state) => {
      state.nfts.clear();
      nfts.forEach((nft) => state.nfts.set(nft.token_id, nft));
      return state;
    }),
  setGrade: (tokenId: string, grade: ENFT_GRADE) =>
    set((state) => {
      state.grades.set(tokenId, grade);
      return state;
    }),
  setGrades: (grades: Map<string, ENFT_GRADE>) =>
    set((state) => {
      state.grades.clear();
      grades.forEach((grade, tokenId) => state.grades.set(tokenId, grade));
      return state;
    }),
  setLoading: (loading: boolean) =>
    set((state) => {
      state.loading = loading;
      return state;
    }),
  setBonus: (tokenId: string, bonus: boolean) =>
    set((state) => {
      state.bonuses.set(tokenId, bonus);
      return state;
    }),
  setBonuses: (bonuses: Map<string, boolean>) =>
    set((state) => {
      state.bonuses.clear();
      bonuses.forEach((bonus, tokenId) => state.bonuses.set(tokenId, bonus));
      return state;
    }),

  getIsLoading: () => get().loading,
  getGradeById: (tokenId: string) => get().grades.get(tokenId) as ENFT_GRADE,
  getNFTById: (tokenId: string) => get().nfts.get(tokenId) as INFTToken,
  getBonusById: (tokenId: string) => (get().bonuses.get(tokenId) as boolean) || false,
}));

export default useNFTsStore;
