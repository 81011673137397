import React from 'react';

import './CoomingSoon.scss';

const withComingSoon = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <div style={{ filter: 'blur(5px)' }}>
          <WrappedComponent {...props} />
        </div>

        <div className="coming-soon-wrapper">
          <span>Coming Soon...</span>
        </div>
      </div>
    );
  };
};

export default withComingSoon;
