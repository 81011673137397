import { motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';
import withComingSoon from 'components/CommingSoon';

const ComingSoonNFTs = () => (
  <motion.div className="nft-page-body-content-list coming-soon-nfts" {...defaultAnimation}>
    <div className="nft-page-body-content-list-header">
      <p>???</p>
      <p className="nft-page-body-content-list-header-count">?</p>
    </div>
    {withComingSoon(() => (
      <ul>
        <li className="nft-page-body-content-list-item">
          <div style={{ width: '160px', height: '160px' }} />
        </li>
        <li className="nft-page-body-content-list-item">
          <div style={{ width: '160px', height: '160px' }} />
        </li>
        <li className="nft-page-body-content-list-item">
          <div style={{ width: '160px', height: '160px' }} />
        </li>
      </ul>
    ))({})}
  </motion.div>
);

export default ComingSoonNFTs;
