import { ENFT_GRADE } from 'interface/nft';

export const NFT_REWARDS = new Map<number, { coins: number; tickets: number; grade: string }>([
  [1, { coins: 300_000, tickets: 100, grade: 'Whale' }],
  [2, { coins: 250_000, tickets: 60, grade: 'Shark' }],
  [3, { coins: 200_000, tickets: 50, grade: 'Unicorn Hunter' }],
  [4, { coins: 175_000, tickets: 40, grade: 'Blockchain Gurur' }],
  [5, { coins: 150_000, tickets: 35, grade: 'ICO Insider' }],
  [6, { coins: 125_000, tickets: 30, grade: 'Token Flipper' }],
  [7, { coins: 100_000, tickets: 25, grade: 'Yield Farmer' }],
  [8, { coins: 75_000, tickets: 20, grade: 'HODLer' }],
  [9, { coins: 50_000, tickets: 15, grade: 'Moon Trader' }],
  [10, { coins: 20_000, tickets: 10, grade: 'Noob' }],
]);

export const NFT_LEVEL_TRAIT_TYPE = 'Torso';

export const NFT_LEVEL_BY_GRADE = new Map<ENFT_GRADE, number>([
  [ENFT_GRADE.Whale, 1],
  [ENFT_GRADE.Shark, 2],
  [ENFT_GRADE.Hunter, 3],
  [ENFT_GRADE.Guru, 4],
  [ENFT_GRADE.ICO, 5],
  [ENFT_GRADE.Flipper, 6],
  [ENFT_GRADE.Farmer, 7],
  [ENFT_GRADE.Holder, 8],
  [ENFT_GRADE.Moon, 9],
  [ENFT_GRADE.Noob, 10],
]);
