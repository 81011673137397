import Button from 'components/Button';
import { motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';

const NoNFTs = () => {
  const NFTBuyLink = 'http://t.me/herewalletbot/app?startapp=web-mitte-pitchtalk';

  const openNFTLink = () => window.open(NFTBuyLink, '_blank', 'noreferrer noopener');

  return (
    <motion.div className="no-nfts" {...defaultAnimation}>
      <img style={{ height: '164px' }} src="images/no-nfts-image.png" alt="No NFTs" />
      <h3>No NFTs yet?</h3>
      <Button
        className="buy-nft-button"
        color="grey"
        onClick={openNFTLink}
        text={
          <>
            Start collecting!
            <img src="images/arrow.svg" alt="Arrow" style={{ width: '16px', height: '16px' }} />
          </>
        }
      />
    </motion.div>
  );
};

export default NoNFTs;
