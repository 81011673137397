import { LEVELS } from 'constants/levels';

import './ProgressBar.scss';
import classNames from 'classnames';

interface IProgressBarProps {
  level: 1 | 2 | 3;
  progress?: number;
  isNew?: boolean;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ level, progress = 0, isNew = true }) => {
  const levelDetails = LEVELS[level];

  return (
    <div className="progress-bar-container">
      <img className="level-image" src={levelDetails.image} alt={levelDetails.level.toString()} />
      <span>{levelDetails.name}</span>
      <div
        className={classNames('progress-bar', {
          'progress-bar--new': isNew,
        })}>
        <div
          style={{ width: `${isNew ? 85 : progress}%`, background: levelDetails.color }}
          className="progress-bar__inner"
        />
      </div>
      {isNew && <p className="coming-soon">Coming Soon</p>}
      <img className="arrow-image" src="images/arrow.svg" alt=">" />
    </div>
  );
};

export default ProgressBar;
