export interface INFTMetadata {
  title?: string;
  description?: string;
  media?: string;
  media_hash?: string;
  copies?: number;
  issued_at?: string;
  expires_at?: string;
  starts_at?: string;
  updated_at?: string;
  extra?: string;
  reference?: string;
  reference_hash?: string;
}

export interface INFTToken {
  token_id: string;
  owner_id: string;
  metadata: INFTMetadata;
}

export type INFTTokens = INFTToken[];

export interface INFTAtribute {
  trait_type: string;
  value: string;
}

export interface INFTLevelsResponse {
  attributes: INFTAtribute[];
}

export enum ENFT_GRADE {
  Whale = 'Whale',
  Shark = 'Shark',
  Hunter = 'Hunter',
  Guru = 'Guru',
  ICO = 'ICO',
  Flipper = 'Flipper',
  Farmer = 'Farmer',
  Holder = 'Holder',
  Moon = 'Moon',
  Noob = 'Noob',
}
