import { useEffect } from 'react';
import useWeb3Store from 'store/web3.store';
import { setupHotWallet } from '@hot-wallet/sdk/adapter/near';
import { setupWalletSelector } from '@near-wallet-selector/core';
import useMainStore from 'store/main.store';
import { setupModal } from '@near-wallet-selector/modal-ui';
import { getUserNFTs, getUserNFTsBonuses, getUserNFTsGrades } from 'api/nft';
import useNFTsStore from 'store/nfts.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';

const network = 'mainnet';

const initSelector = async () => {
  const selector = await setupWalletSelector({ modules: [setupHotWallet()], network });
  const modal = setupModal(selector, { contractId: '' });
  return { selector, modal };
};

export const useNearWallet = () => {
  const { showModal } = useModalStore();
  const user = useMainStore((state) => state.user);
  const { setGrades, setNFTs, setBonuses, setLoading: setIsLoadingNFT } = useNFTsStore();
  const { setNearWallet, setModal, setSelector } = useWeb3Store();

  useEffect(() => {
    if (user?.id) {
      initSelector().then(async ({ selector, modal }) => {
        try {
          const wallet = await selector.wallet();
          const accounts = await wallet.getAccounts();
          const accountId = accounts?.[0]?.accountId;
          const walletWithAccountId = { ...wallet, accountId };
          if (accountId) {
            if (!user.hotNearWalletBonusClaimed) {
              showModal(EModals.CONNECT_HOT_REWARD, {
                onSuccess: () => setNearWallet(walletWithAccountId),
              });
            } else {
              if (user.hotNearWalletAddress !== accountId) {
                showModal(EModals.CONNECT_HOT_REWARD, {
                  isConfirming: true,
                  onSuccess: () => setNearWallet(walletWithAccountId),
                });
              }
            }
          }
          setNearWallet(walletWithAccountId);
        } catch (error) {
          console.log(error);
        }

        selector.on('signedIn', async () => {
          const wallet = await selector.wallet();
          const accounts = await wallet.getAccounts();
          const accountId = accounts?.[0]?.accountId;
          const walletWithAccountId = { ...wallet, accountId };
          if (accountId) {
            if (!user.hotNearWalletBonusClaimed) {
              showModal(EModals.CONNECT_HOT_REWARD, {
                onSuccess: () => setNearWallet(walletWithAccountId),
              });
            } else {
              if (user.hotNearWalletAddress !== accountId) {
                showModal(EModals.CONNECT_HOT_REWARD, {
                  isConfirming: true,
                  onSuccess: () => setNearWallet(walletWithAccountId),
                });
              }
            }
          }
          setNearWallet(walletWithAccountId);
        });

        selector.on('signedOut', async () => {
          setNearWallet(null);
        });

        setModal(modal);
        setSelector(selector);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.hotNearWalletBonusClaimed, user?.hotNearWalletAddress]);

  useEffect(() => {
    const getNFTs = async () => {
      if (user?.hotNearWalletAddress && user?.hotNearWalletBonusClaimed) {
        setIsLoadingNFT(true);
        try {
          const nfts = await getUserNFTs(user?.hotNearWalletAddress);
          const grades = await getUserNFTsGrades(nfts);
          const bonuses = await getUserNFTsBonuses(nfts);
          setNFTs(nfts);
          setGrades(grades);
          setBonuses(bonuses);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingNFT(false);
        }
      }
    };
    getNFTs();
  }, [user?.hotNearWalletAddress]);
};
