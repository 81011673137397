import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { formatNumber } from 'utils/formatNumber';
import TasksList from 'components/Tasks';
import { getTaskIcon } from 'constants/tasks';
import { ETaskSlug, ETaskStatus, ITaskProgress } from 'interface/task';
import useBalanceStore from 'store/balance.store';
import { verifyTasks, startBasicTask } from 'api/tasks';
import { sleep } from 'utils/time';
import useMainStore from 'store/main.store';
import useTasksStore from 'store/tasks.store';

import './Tasks.scss';

const Tasks: React.FC = () => {
  const navigate = useNavigate();
  const { coins, tickets, updateCoins } = useBalanceStore();
  const tg = useMainStore((state) => state.tgWebApp);
  const {
    getDailyTasksRender,
    getGeneralTasksRender,
    getGeneralTasksProgress,
    getDailyTasksProgress,
    isAllGeneralTasksCompleted,
    updateGeneralTaskStatus,
  } = useTasksStore();

  const dailyTasksRender = getDailyTasksRender();
  const generalTasksRender = getGeneralTasksRender();
  const generalTasksProgress = getGeneralTasksProgress();
  const dailyTasksProgress = getDailyTasksProgress();
  const isGeneralCompleted = isAllGeneralTasksCompleted();

  // TODO: Make hook
  const onGeneralTaskClick = async (task: ITaskProgress) => {
    if (tg?.openLink) {
      if (
        task.template.slug === ETaskSlug.BASIC_TG ||
        task.template.slug === ETaskSlug.BASIC_CHAT
      ) {
        tg.openTelegramLink(task.template.url);
      } else {
        tg.openLink(task.template.url);
      }
    } else {
      window.open(task.template.url, '_blank', 'noopener noreferrer');
    }
    await startBasicTask(task.id);
    updateGeneralTaskStatus(task.id, ETaskStatus.VERIFY_REQUESTED);
    await sleep(5000);
    const resp = await verifyTasks();
    if (resp) {
      updateCoins(resp.coins);
      resp.tasks.forEach((task) => updateGeneralTaskStatus(task.id, task.status));
    }
  };

  useEffect(() => {
    const checkAndVerifyTasks = async () => {
      const hasPendingTasks = generalTasksRender.some(
        (task) => task.status === ETaskStatus.VERIFY_REQUESTED,
      );
      if (hasPendingTasks) {
        const resp = await verifyTasks();
        if (resp) {
          resp.coins && updateCoins(resp.coins);
          resp.tasks.forEach((task) => updateGeneralTaskStatus(task.id, task.status));
        }
      }
    };

    checkAndVerifyTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalTasksProgress]);

  return (
    <motion.div
      className="tasks-page"
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <div className="tasks-page-header">
        <h4>Your balance</h4>
        <div className="tasks-page-header__balance">
          <p>
            {formatNumber(coins)}
            <img className="point" src="images/point.png" alt="Points" />
          </p>
          <p>
            {formatNumber(tickets)}
            <img className="ticket" src="images/ticket.svg" alt="Tickets" />
          </p>
        </div>
      </div>
      <div className="tasks-page-body">
        <TasksList label="General" completed={generalTasksProgress} className="general-tasks-list">
          {generalTasksRender.map((task, i) => (
            <li
              className={task.status ? task.status : ''}
              key={i}
              onClick={() => onGeneralTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <div className="task-info">
                <p className="task">{task.template.title}</p>
                <p className="reward">
                  <img src="images/point.png" alt="Tickets" />
                  <span>+{task.template.rewardCoins}</span>
                </p>
              </div>
              <p className="status-icon">
                <img src={getTaskIcon(task.status)} alt="" />
              </p>
            </li>
          ))}
        </TasksList>
        <TasksList
          label="Daily Tasks"
          completed={dailyTasksProgress}
          isDisabled={!isGeneralCompleted}
          className="daily-tasks-list">
          {dailyTasksRender.map((task, i) => (
            <li
              className={task.status ? task.status : ''}
              key={i}
              onClick={() => navigate(`/template?slug=${task.template.slug}`)}>
              <img src={task.template.image} alt="" />
              <div className="task-info">
                <p className="task">{task.template.title}</p>
                <p className="reward">
                  <img src="images/ticket.svg" alt="Tickets" />
                  <span>{task.template.rewardTickets} Airdrop Pass</span>
                </p>
              </div>
              {task.status && (
                <p className="status-icon">
                  <img src={getTaskIcon(task.status)} alt="" />
                </p>
              )}
            </li>
          ))}
        </TasksList>
      </div>
    </motion.div>
  );
};

export default Tasks;
