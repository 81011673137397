import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Modals from 'providers/ModalsProvider';
import Main from 'components/Main';
import LoadingScreen from 'components/LoadingScreen';
import { preloadFonts, preloadImages } from 'utils/preload';
import { loginUser } from 'api/user';
import { getFarming } from 'api/farming';
import { getTasks } from 'api/tasks';
import useMainStore from 'store/main.store';
import useBalanceStore from 'store/balance.store';
import useFarmingStore from 'store/farming.store';
import { showErrorToast } from 'utils/toastUtils';
import { setItem } from 'utils/storage';
import { IDailyReward } from 'interface/user';
import { ETaskType } from 'interface/task';

import Home from 'pages/home';
import Stats from 'pages/stats';
import Referrals from 'pages/referrals';
import Template from 'pages/template';
import Tasks from 'pages/tasks';
import NFTPage from 'pages/nft';
import AdminPanel from 'pages/admin/panel';
import AdminDaily from 'pages/admin/daily';
import useTasksStore from 'store/tasks.store';
import { currentEnvironment, EEnvironment, GA } from 'config';

import '@near-wallet-selector/modal-ui/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';

const App: React.FC = () => {
  const setTG = useMainStore((state) => state.setTG);
  const setUser = useMainStore((state) => state.setUser);
  const { updateCoins, updateTickets, updateReferralRewards } = useBalanceStore();
  const { setFarming, setIsLoading: setIsLoadingFarming } = useFarmingStore();
  const { updateDailyTaskStatus, setGeneralTasks } = useTasksStore();
  const setUserHotWalletBonusClaimed = useMainStore((state) => state.setUserHotWalletBonusClaimed);
  const setUserHotWallet = useMainStore((state) => state.setUserHotWallet);
  const [isLoadedApp, setIsLoadedApp] = useState(false);
  const [user, setIsUser] = useState(false);

  const [dailyReward, setDailyReward] = useState<IDailyReward | null>(null);

  useEffect(() => {
    (async () => {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();

      const color = '#090E15';
      tg.setHeaderColor(color);
      tg.setBottomBarColor(color);
      tg.setBackgroundColor(color);

      if (currentEnvironment === EEnvironment.MAINNET) {
        ReactGA.initialize(GA);
        ReactGA.send('pageview');
      }

      await preloadImages();
      await preloadFonts();

      setTG(tg);
      setIsLoadedApp(true);

      const params = new URLSearchParams(tg.initData);
      const referralCode = params.get('start_param') || '';
      const telegramId = (tg.initDataUnsafe.user?.id || '').toString();
      const username = (tg.initDataUnsafe.user?.username || '').toString();
      const photoUrl = (tg.initDataUnsafe.user?.photo_url || '').toString();
      const hash = tg.initData;

      if (!telegramId) {
        showErrorToast('Cannot find telegram id!');
        return;
      }
      if (!username) {
        showErrorToast('Please set up your username!');
        return;
      }
      const userData = await loginUser(telegramId, username, hash, referralCode, photoUrl);
      if (!userData) {
        console.log('Cannot login user');
        return;
      }

      setItem('token', userData.accessToken);
      setUser(userData.user);

      updateTickets(userData.user.tickets);
      updateCoins(userData.user.coins);
      updateReferralRewards(userData.user.referralRewards);

      const dailyReward = userData.dailyRewards;
      setDailyReward(dailyReward);

      if (userData.user.farmingId) {
        const farming = await getFarming();
        setFarming(farming);
      }
      setIsLoadingFarming(false);

      const tasks = await getTasks();
      setGeneralTasks(tasks.filter((task) => task.template.type === ETaskType.BASIC));
      tasks
        .filter((task) => task.template.type === ETaskType.DAILY)
        .forEach((task) => {
          updateDailyTaskStatus(task.template.slug, task.status);
        });

      ReactGA.event({
        category: 'User',
        action: 'Active Session',
        label: 'Telegram Mini App',
      });

      setUserHotWallet(userData.user.hotNearWalletAddress || '');
      setUserHotWalletBonusClaimed(userData.user.hotNearWalletBonusClaimed);

      setIsUser(!!userData.user.id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isLoadedApp ? <Main isUser={user} dailyReward={dailyReward} /> : <LoadingScreen />
            }>
            <Route index element={user ? <Home /> : <LoadingScreen />} />
            <Route path="/stats" element={user ? <Stats /> : <LoadingScreen />} />
            <Route path="/tasks" element={user ? <Tasks /> : <LoadingScreen />} />
            <Route path="/referrals" element={user ? <Referrals /> : <LoadingScreen />} />
            <Route path="/nft" element={user ? <NFTPage /> : <LoadingScreen />} />
            <Route path="/template" element={user ? <Template /> : <LoadingScreen />} />
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/admin-daily" element={<AdminDaily />} />
            <Route path="/*" element={<LoadingScreen />} />
          </Route>
        </Routes>
      </Router>
      <Modals />
      <ToastContainer position="top-center" hideProgressBar />
    </>
  );
};

export default App;
