export const formatNumber = (number: number = 0, format: 'short' | 'long' = 'long'): string => {
  if (format === 'short') {
    if (Math.abs(number) >= 1_000_000_000) {
      const billions = Math.floor(number / 1_000_000_000);
      const rest = Math.floor((number % 1_000_000_000) / 1_000_000);
      return `${billions}B${rest > 0 ? ` ${rest}M` : ''}`;
    } else if (Math.abs(number) >= 1_000_000) {
      const millions = Math.floor(number / 1_000_000);
      const rest = Math.floor((number % 1_000_000) / 1_000);
      return `${millions}M${rest > 0 ? ` ${rest}K` : ''}`;
    }
  }

  return new Intl.NumberFormat('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};
