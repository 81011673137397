import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Button.scss';

interface IButtonProps {
  text: string | ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  color?: 'white' | 'grey' | 'primary' | 'secondary';
  style?: Record<string, string>;
}

const Button: React.FC<IButtonProps> = ({
  text,
  onClick,
  disabled,
  className,
  color = 'white',
  style = {},
}) => {
  return (
    <button
      style={style}
      className={classNames(className, `button-color--${color}`)}
      onClick={onClick}
      disabled={disabled}>
      {text}
    </button>
  );
};

export default Button;
