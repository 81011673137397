import { loginAdmin } from 'api/user';
import User from 'components/User';
import { EUserRoles } from 'interface/user';
import React from 'react';
import { NavLink } from 'react-router-dom';
// @ts-ignore
import TelegramLoginButton from 'react-telegram-login';
import { botName } from 'config';
import useMainStore from 'store/main.store';
import { setItem } from 'utils/storage';

import './AdminPanel.scss';

const AdminPanel: React.FC = () => {
  const { user, setUser } = useMainStore();

  const handleLoginClick = async (user: any) => {
    const res = await loginAdmin(user);
    if (res) {
      setItem('token', res.accessToken);
      setUser(res.user);
    }
  };

  return (
    <div className="admin-panel-page">
      {user && (user.role === EUserRoles.ADMIN || user?.role === EUserRoles.SUPER_ADMIN) ? (
        <>
          <div className="admin-panel-page-header">
            <h2>Admin panel</h2>
            <User photoUrl={user.photoUrl} username={user.username} />
          </div>
          <div className="admin-panel-page-navigation">
            <h3>Admin Panel Navigation</h3>
            <nav>
              <NavLink to={'/admin-daily'}>Daily Tasks Check</NavLink>
            </nav>
          </div>
        </>
      ) : (
        <TelegramLoginButton dataOnauth={handleLoginClick} botName={botName} />
      )}
    </div>
  );
};

export default AdminPanel;
