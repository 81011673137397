import { clearTasks } from 'api/tasks';
import User from 'components/User';
import { EModals } from 'interface/modals';
import React from 'react';
import useBalanceStore from 'store/balance.store';
import useMainStore from 'store/main.store';
import useModalStore from 'store/modals.store';
import useWeb3Store from 'store/web3.store';
import { formatNumber } from 'utils/formatNumber';

import './Header.scss';

const Header: React.FC = () => {
  const { nearWallet } = useWeb3Store();
  const { showModal } = useModalStore();
  const user = useMainStore((state) => state.user);
  const { tickets } = useBalanceStore();

  const username = user?.username;
  const photoUrl = user?.photoUrl;

  const onFAQClick = () => {
    showModal(EModals.FAQ);
  };

  const onWalletClick = () => {
    showModal(EModals.DISCONNECT_WALLET, {});
  };

  return (
    <div className="header">
      <User
        photoUrl={photoUrl || ''}
        username={username || ''}
        isShowWallet
        wallet={
          user?.hotNearWalletBonusClaimed && user?.hotNearWalletAddress
            ? user?.hotNearWalletAddress
            : nearWallet?.accountId
        }
        onWalletClick={onWalletClick}
      />
      <div className="user-tickets" onClick={() => clearTasks()}>
        <img src="images/ticket.svg" alt="Tickets" />
        <span>{formatNumber(tickets, 'short')}</span>
      </div>
      <button className="help-button" onClick={onFAQClick}>
        <img src="images/help.png" alt="?" />
      </button>
    </div>
  );
};

export default Header;
