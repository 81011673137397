import { motion } from 'framer-motion';
import Header from 'components/Header';
import useWeb3Store from 'store/web3.store';
import { useState } from 'react';
import LoadingScreen from 'components/LoadingScreen';
import ComingSoonNFTs from './components/ComingSoonNFTs';
import NoNFTs from './components/NoNFTs';
import ConnectHotWallet from './components/ConnectHotWallet';
import { defaultAnimation } from 'styles/animations';
import { getUserNFTs, getUserNFTsBonuses, getUserNFTsGrades } from 'api/nft';
import Button from 'components/Button';
import useNFTsStore from 'store/nfts.store';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';

import './NFTPage.scss';

const NFTPage = () => {
  const wallet = useWeb3Store();
  const {
    nfts,
    bonuses,
    getGradeById,
    setGrades,
    setNFTs,
    setBonuses,
    loading: isLoading,
  } = useNFTsStore();
  const { showModal } = useModalStore();
  const [isReloading, setIsReloading] = useState(false);

  const isNFTs = nfts.size;
  const isConnected = wallet.nearWallet?.accountId;

  const onClaimBonus = async (tokenId: string) => {
    const grade = getGradeById(tokenId);
    showModal(EModals.NFT_REWARD, { grade, nftId: tokenId });
  };

  const refreshNFTs = async () => {
    setIsReloading(true);
    const nfts = await getUserNFTs(wallet.nearWallet?.accountId || '');
    const grades = await getUserNFTsGrades(nfts);
    const bonuses = await getUserNFTsBonuses(nfts);
    setNFTs(nfts);
    setGrades(grades);
    setBonuses(bonuses);
    setIsReloading(false);
  };

  return (
    <motion.div className="nft-page" {...defaultAnimation}>
      <Header />
      <div className="nft-page-body">
        <div className="nft-page-body-content">
          {isLoading ? (
            <LoadingScreen />
          ) : isConnected ? (
            <>
              <h2 className="nft-page-body-content-title">My Collection</h2>
              {isNFTs ? (
                <>
                  <motion.div
                    className="nft-page-body-content-list pitchtalk-nfts"
                    {...defaultAnimation}>
                    <div className="nft-page-body-content-list-header">
                      <p>Pitchtalk NFTs</p>
                      <p className="nft-page-body-content-list-header-count">
                        {nfts.size}
                        <img onClick={refreshNFTs} src="images/reload.png" alt="Reload" />
                      </p>
                    </div>
                    <ul
                      style={{
                        opacity: isReloading ? 0.5 : 1,
                        filter: isReloading ? 'blur(5px)' : 'none',
                        pointerEvents: isReloading ? 'none' : 'auto',
                      }}>
                      {Array.from(nfts.values()).map((nft) => (
                        <li key={nft.token_id} className="nft-page-body-content-list-item">
                          <img
                            style={{ width: '160px', height: '160px' }}
                            src={nft.metadata.media}
                            alt={nft.metadata.title}
                          />
                          <p>#{nft.token_id}</p>
                          <Button
                            disabled={!!bonuses.get(nft.token_id)}
                            color={!!bonuses.get(nft.token_id) ? 'grey' : 'primary'}
                            text={
                              <>
                                {!!bonuses.get(nft.token_id) ? 'Claimed' : 'Claim Bonus'}
                                <img src="images/prize.svg" alt="" />
                              </>
                            }
                            onClick={() => onClaimBonus(nft.token_id)}
                          />
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                  <ComingSoonNFTs />
                </>
              ) : (
                <NoNFTs />
              )}
            </>
          ) : (
            <ConnectHotWallet />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default NFTPage;
