import { LoginStreak } from 'constants/dailyRewards';
import { IReferralEarning } from './referrals';

export interface IDailyReward {
  coins: number;
  tickets: number;
  loginStreak: number;
  isNewDay: boolean;
}

export interface IUserLoginResponse {
  accessToken: string;
  user: IUser;
  isNewUser: boolean;
  dailyRewards: IDailyReward;
}

export interface IAdminLoginResponse {
  accessToken: string;
  user: IUser;
}

export interface IUser {
  id: string;
  telegramId?: string;
  username: string;
  photoUrl: string;
  role: EUserRoles;

  loginStreak: LoginStreak;
  lastLogin: Date;

  coins: number;
  tickets: number;

  referralCode: string;
  referredBy?: IReferralEarning;
  referredById?: string;
  referralRewards: number;
  referralEarnings: IReferralEarning[];
  lastReferralRewardClaim: Date;

  farmingId?: string;
  basicTasksCompleted: boolean;

  createdAt: Date;
  updatedAt: Date;

  hotNearWalletAddress: string | null;
  hotNearWalletBonusClaimed: boolean;
}

export enum EUserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
}
