import { providers } from 'near-api-js';
import { IRPCProviderService, RPCProviderService } from './RPCProvider';
import { INFTTokens } from 'interface/nft';
import { nftContractId } from 'config';

class NearService {
  private static provider: IRPCProviderService;

  public static initialize() {
    const provider = new providers.JsonRpcProvider({ url: 'https://near-rpc.hapilabs.one' });
    this.provider = new RPCProviderService(provider);
  }

  static async getUserNFTs(accountId: string): Promise<INFTTokens> {
    try {
      const nfts = await NearService.provider.viewFunction<{ account_id: string }, INFTTokens>(
        'nft_tokens_for_owner',
        nftContractId,
        { account_id: accountId },
      );
      return nfts || [];
    } catch (error) {
      console.error('Error fetching NFTs:', error);
      return [];
    }
  }
}

NearService.initialize();

export default NearService;
