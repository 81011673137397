import React, { useEffect } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Footer from 'components/Footer';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';
import ScrollToTop from 'components/ScrollToTop';
import useBalanceStore from 'store/balance.store';
import { IDailyReward } from 'interface/user';

import './Main.scss';
import { useNearWallet } from 'hooks/web3/useNearWallet';

interface IMainProps {
  isUser: boolean;
  dailyReward?: IDailyReward | null;
}

const AnimatedOutlet = (): React.JSX.Element => {
  const location = useLocation();
  const element = useOutlet();

  return (
    <AnimatePresence mode="wait" initial={true}>
      {element && React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
};

const Main: React.FC<IMainProps> = ({ isUser, dailyReward }) => {
  const balance = useBalanceStore();
  const { showModal } = useModalStore();

  useEffect(() => {
    if (dailyReward && dailyReward?.loginStreak >= 1 && dailyReward.isNewDay) {
      showModal(EModals.DAILY_REWARD, { dailyReward });
      balance.addCoins(dailyReward.coins);
      balance.addTickets(dailyReward.tickets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyReward?.loginStreak, dailyReward?.isNewDay]);

  useNearWallet();

  return (
    <div className="container">
      <ScrollToTop />
      <AnimatedOutlet />
      {isUser && <Footer />}
    </div>
  );
};

export default Main;
