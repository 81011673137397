import { ENFT_GRADE } from './nft';
import { IDailyReward } from './user';

export enum EModals {
  FAQ = 'FAQ',
  DAILY_REWARD = 'DAILY_REWARD',
  NFT_REWARD = 'NFT_REWARD',
  CONNECT_HOT_REWARD = 'CONNECT_HOT_REWARD',
  DISCONNECT_WALLET = 'DISCONNECT_WALLET',
  EMPTY = 'EMPTY',
}

export interface ICloseModal extends React.PropsWithChildren {
  closeModal: () => void;
}

export type IFAQModal = ICloseModal;

export type IDisconnectWalletModal = ICloseModal;

export interface IConnectHotRewardModal extends ICloseModal {
  isConfirming?: boolean;
  onSuccess: () => void;
}

export interface INFTRewardModal extends ICloseModal {
  nftId: string;
  grade: ENFT_GRADE;
}

export interface IDailyRewardModal extends ICloseModal {
  dailyReward: IDailyReward;
}

export type IModalsProps = {
  [EModals.FAQ]: IFAQModal;
  [EModals.DAILY_REWARD]: IDailyRewardModal;
  [EModals.NFT_REWARD]: INFTRewardModal;
  [EModals.CONNECT_HOT_REWARD]: IConnectHotRewardModal;
  [EModals.DISCONNECT_WALLET]: IDisconnectWalletModal;
  [EModals.EMPTY]: any;
};

export interface IModalInstance {
  type: EModals;
  props: IModalsProps[EModals];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.LazyExoticComponent<React.ComponentType<any>> | null;
}
