import axios from 'axios';
import { NFT_LEVEL_TRAIT_TYPE } from 'constants/nft';
import { INFTLevelsResponse, ENFT_GRADE, INFTToken } from 'interface/nft';
import NearService from 'services/NearService';
import { handleApiError } from './errorHandlerService';
import api from './APIService';

export const getNFTGrade = async (nft: string): Promise<ENFT_GRADE> => {
  try {
    const response = await axios.get<INFTLevelsResponse>(nft);
    const attributes = response.data.attributes;
    const grade = attributes.find((attr) => attr.trait_type === NFT_LEVEL_TRAIT_TYPE)?.value;
    return grade as ENFT_GRADE;
  } catch (error) {
    return ENFT_GRADE.Noob;
  }
};

export const getUserNFTs = async (accountId: string): Promise<INFTToken[]> => {
  try {
    const nfts = await NearService.getUserNFTs(accountId);
    return nfts;
  } catch (error) {
    return [];
  }
};

export const getUserNFTsGrades = async (nfts: INFTToken[]): Promise<Map<string, ENFT_GRADE>> => {
  try {
    const grades = new Map<string, ENFT_GRADE>();
    const gradePromises = nfts.map(async (nft) => {
      const grade = await getNFTGrade(nft.metadata.extra || '');
      grades.set(nft.token_id, grade);
    });
    await Promise.all(gradePromises);
    return grades;
  } catch (error) {
    return new Map<string, ENFT_GRADE>();
  }
};

export const getUserNFTsBonuses = async (nfts: INFTToken[]): Promise<Map<string, boolean>> => {
  try {
    const response = await api.get<{ [nftId: string]: boolean }>(
      `/tasks/pitchtalk-holder-bonus?nfts=${nfts.map((nft) => nft.token_id).join(',')}`,
    );
    return new Map<string, boolean>(Object.entries(response.data || {}));
  } catch (error) {
    return new Map<string, boolean>();
  }
};

export const claimNFTBonus = async (
  nftId: string,
): Promise<{ rewards: { coins: number; tickets: number } } | boolean> => {
  try {
    const response = await api.post('/tasks/pitchtalk-holder-bonus', { nftId: nftId });
    return response.data;
  } catch (error) {
    handleApiError(error);
    return false;
  }
};
