import { ETaskStatus, ETaskType, ITaskProgress } from 'interface/task';
import api from './APIService';
import { handleApiError } from './errorHandlerService';
import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import { IUser } from 'interface/user';
import { sleep } from 'utils/time';
import { currentEnvironment, EEnvironment } from 'config';

export const submitDailyTask = async (
  slug: string,
  proof: string,
): Promise<ITaskProgress | null> => {
  try {
    const response = await api.post<ITaskProgress>('tasks/create-daily', { slug, proof });
    showSuccessToast('Success Submit');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const submitBasicTask = async (id: string): Promise<ITaskProgress | null> => {
  try {
    const response = await api.post<ITaskProgress>(`tasks/${id}/verify`, {});
    const taskStatus = response.data.status;
    if (
      taskStatus === ETaskStatus.COMPLETED_CLAIMED ||
      taskStatus === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      showSuccessToast('Success Task Verified');
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const startBasicTask = async (id: string): Promise<ITaskProgress | null> => {
  try {
    const response = await api.post<ITaskProgress>(`tasks/${id}/start`, {});
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const clearTasks = async () => {
  if (currentEnvironment !== EEnvironment.TESTNET) return;
  try {
    const response = await api.get<ITaskProgress>('tasks/clear', {});
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getTasks = async (type?: ETaskType): Promise<ITaskProgress[]> => {
  const response = await api.get<ITaskProgress[]>(`tasks${type ? `?type=${type}` : ''}`);
  return response.data;
};

export const verifyTasks = async (): Promise<{ coins: number; tasks: ITaskProgress[] } | null> => {
  try {
    const response = await api.get<ITaskProgress[]>('tasks/verify');
    if (response.data.length) {
      const completedTasks = response.data.filter(
        (task) =>
          task.status === ETaskStatus.COMPLETED_CLAIMED ||
          task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
      );

      let user;
      if (completedTasks.length > 0) {
        await sleep(1000);
        user = await api.get<IUser>('users/me');
      }
      response.data.forEach((task) => {
        if (
          task.status === ETaskStatus.COMPLETED_CLAIMED ||
          task.status === ETaskStatus.COMPLETED_NOT_CLAIMED
        ) {
          showSuccessToast('Task successfully verified!.');
        } else {
          showErrorToast('Cannot verify task, please try again!');
        }
      });
      return { coins: user ? user.data.coins : 0, tasks: response.data };
    }
    return null;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

// ADMIN
export const getTasksList = async (page: number, limit: number): Promise<ITaskProgress[]> => {
  try {
    const response = await api.get<ITaskProgress[]>(
      `tasks/list?page=${page}&limit=${limit}&status=${ETaskStatus.VERIFY_REQUESTED}&type=${ETaskType.DAILY}`,
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return [];
  }
};

export const changeTaskStatus = async (id: string, status: ETaskStatus) => {
  try {
    const response = await api.post<ITaskProgress>(`tasks/${id}/change-status`, { status });
    showSuccessToast(
      `Success Task ${status === ETaskStatus.VERIFY_REJECTED ? 'Rejected' : 'Verified'} `,
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};
