import React, { useState, useEffect } from 'react';
import Button from 'components/Button';
import useBalanceStore from 'store/balance.store';
import { claimFarming, createFarming, getFarming } from 'api/farming';
import { FARMING_MAX_REWARD, FARMING_MAX_COINS_PER_HOUR, FARMING_MAX_HOURS } from 'constants/main';
import useFarmingStore from 'store/farming.store';

import './Claim.scss';

const Claim: React.FC = () => {
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const [coinsFarming, setCoinsFarming] = useState(0);
  const { farming, isLoading: isLoadingFarming, setFarming, setIsLoading } = useFarmingStore();

  useEffect(() => {
    if (farming) {
      const calculateFarmingTokens = () => {
        const now = new Date();
        const endTime = new Date(farming?.endTime);
        const farmingMaxHoursInMillis = FARMING_MAX_HOURS * 3600 * 1000;
        const startTime = new Date(endTime.getTime() - farmingMaxHoursInMillis);
        const elapsedTimeInSeconds = Math.min(
          (now.getTime() - startTime.getTime()) / 1000,
          FARMING_MAX_HOURS * 3600,
        );
        const elapsedHours = elapsedTimeInSeconds / 3600;
        const totalTokens = Math.min(elapsedHours * FARMING_MAX_COINS_PER_HOUR, FARMING_MAX_REWARD);
        setCoinsFarming(Number(totalTokens.toFixed(0)));
      };

      calculateFarmingTokens();
      const interval = setInterval(() => {
        calculateFarmingTokens();
      }, 60000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farming?.endTime]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, '0')}h ${mins.toString().padStart(2, '0')}m`;
  };

  const onButtonClick = async () => {
    try {
      setIsLoading(true);
      if (farming?.endTime) {
        const user = await claimFarming();
        if (user) {
          updateCoins(user.coins);
          const farming = await getFarming();
          setFarming(farming);
        }
      } else {
        const farming = await createFarming();
        setFarming(farming);
      }
    } catch (error) {
      console.error('Error claiming farming', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isNewUser = isLoadingFarming ? true : !farming;

  const progressPercent = (coinsFarming / FARMING_MAX_REWARD) * 100;

  return (
    <div className="claim-container">
      <Button
        className={isLoadingFarming ? 'loading' : ''}
        style={{ '--progress-width': `${isLoadingFarming ? 100 : progressPercent}%` }}
        color={isNewUser ? 'white' : 'primary'}
        disabled={isNewUser ? false : coinsFarming < FARMING_MAX_REWARD}
        text={
          isNewUser ? (
            'Start Farming'
          ) : coinsFarming < FARMING_MAX_REWARD ? (
            <>
              Farming... <span>{coinsFarming < 0 ? 0 : coinsFarming}</span>
            </>
          ) : (
            <>
              Claim <span>{coinsFarming}</span> <img src="images/point.png" alt="" />
            </>
          )
        }
        onClick={onButtonClick}
      />
      {isNewUser ? null : coinsFarming >= FARMING_MAX_REWARD ? (
        <p>Claim your points to keep farming</p>
      ) : (
        <p>
          Claim in{' '}
          {formatTime((FARMING_MAX_REWARD - coinsFarming) / (FARMING_MAX_COINS_PER_HOUR / 3600))}
        </p>
      )}
    </div>
  );
};

export default Claim;
