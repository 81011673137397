import { DAILY_TASKS } from 'constants/tasks';
import { ETaskStatus, ITaskProgress } from 'interface/task';
import { compareTasksByStatus } from 'utils/sortTasks';
import { create } from 'zustand';

interface ITasksStore {
  dailyTasks: Map<string, ITaskProgress>;
  setDailyTasks: (dailyTasks: ITaskProgress[]) => void;
  generalTasks: Map<string, ITaskProgress>;
  setGeneralTasks: (generalTasks: ITaskProgress[]) => void;

  updateGeneralTaskStatus: (id: string, newStatus: ETaskStatus) => void;
  updateDailyTaskStatus: (slug: string, newStatus: ETaskStatus) => void;

  getDailyTasksRender: () => ITaskProgress[];
  getGeneralTasksRender: () => ITaskProgress[];
  getGeneralTasksProgress: () => number;
  getDailyTasksProgress: () => number;
  isAllGeneralTasksCompleted: () => boolean;
}

const useTasksStore = create<ITasksStore>((set, get) => ({
  generalTasks: new Map<string, ITaskProgress>(),
  dailyTasks: new Map<string, ITaskProgress>(DAILY_TASKS.map((task) => [task.template.slug, task])),
  setDailyTasks: (dailyTasks: ITaskProgress[]) =>
    set({ dailyTasks: new Map(dailyTasks.map((task) => [task.template.slug, { ...task, status: ETaskStatus.INITIAL }] )) }),
  setGeneralTasks: (generalTasks: ITaskProgress[]) =>
    set({ generalTasks: new Map(generalTasks.map((task) => [task.template.slug, task])) }),

  updateGeneralTaskStatus: (id: string, newStatus: ETaskStatus) => {
    set((state) => {
      const task = Array.from(state.generalTasks.values()).find((task) => task.id === id);
      if (task) {
        task.status = newStatus;
        return {
          generalTasks: new Map(state.generalTasks),
        };
      }
      return state;
    });
  },
  updateDailyTaskStatus: (slug: string, newStatus: ETaskStatus) => {
    set((state) => {
      const task = state.dailyTasks.get(slug);
      if (task) {
        task.status = newStatus;
        return {
          dailyTasks: new Map(state.dailyTasks),
        };
      }
      return state;
    });
  },

  // Нові селектори
  getDailyTasksRender: () =>
    Array.from(get().dailyTasks.values()).sort((a, b) => compareTasksByStatus(a.status, b.status)),
  getGeneralTasksRender: () =>
    Array.from(get().generalTasks.values()).sort((a, b) =>
      compareTasksByStatus(a.status, b.status),
    ),
  getGeneralTasksProgress: () => {
    return Array.from(get().generalTasks.values()).filter(
      (task) =>
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
    ).length;
  },
  getDailyTasksProgress: () => {
    return Array.from(get().dailyTasks.values()).filter(
      (task) =>
        task.status === ETaskStatus.VERIFY_REQUESTED ||
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
    ).length;
  },
  isAllGeneralTasksCompleted: () => {
    const generalTasks = Array.from(get().generalTasks.values());
    return (
      generalTasks.length > 0 &&
      generalTasks.every(
        (task) =>
          task.status === ETaskStatus.COMPLETED_CLAIMED ||
          task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
      )
    );
  },
}));

export default useTasksStore;
