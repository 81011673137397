import { ETaskSlug, ETaskStatus, ETaskType, ITaskProgress } from 'interface/task';
import { DAILY_TASK_REWARD } from './main';
import { IUser } from 'interface/user';

export const getTaskIcon = (status: ETaskStatus) => {
  switch (true) {
    case status === ETaskStatus.INITIAL:
      return 'images/arrow.svg';
    case status === ETaskStatus.COMPLETED_CLAIMED:
    case status === ETaskStatus.COMPLETED_NOT_CLAIMED:
      return 'images/task-completed-icon.png';
    case status === ETaskStatus.VERIFY_REQUESTED:
      return 'images/task-review-icon.png';
    case status === ETaskStatus.VERIFY_REJECTED:
      return 'images/task-rejected-icon.png';
  }
};

export const getDailyTaskStatus = (slug: string, tasks: ITaskProgress[]) =>
  tasks.find((task) => task.template.slug === slug)?.status || ETaskStatus.INITIAL;

export const DAILY_TASKS: ITaskProgress[] = [
  {
    id: '2',
    template: {
      id: '2',
      rewardCoins: 0,
      rewardTickets: DAILY_TASK_REWARD,
      title: 'Share X Post',
      type: ETaskType.DAILY,
      url: '',
      shortDesc: 'Share your post on X',
      longDesc: 'Share your post on X to earn rewards.',
      image: 'images/task/daily-x.png',
      proofRequired: false,
      isVisible: true,
      partnerData: '',
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.DAILY_X,
    },
    templateId: '2',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
  {
    id: '3',
    template: {
      id: '3',
      rewardCoins: 0,
      rewardTickets: DAILY_TASK_REWARD,
      title: 'Share TikTok Post',
      type: ETaskType.DAILY,
      url: '',
      shortDesc: 'Share your post on TikTok',
      longDesc: 'Share your post on TikTok to earn rewards.',
      image: 'images/task/daily-tik-tok.png',
      proofRequired: false,
      isVisible: true,
      partnerData: '',
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.DAILY_TIK_TOK,
    },
    templateId: '3',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
];

export interface IGeneralTask {
  icon: string;
  label: string;
  reward: number;
  status?: ETaskStatus;
  link: string;
  type: 'TELEGRAM' | 'X' | 'YOUTUBE';
}

export const PARTNERS_TASKS = [{ icon: '', label: 'Partner Name', reward: '10' }];
