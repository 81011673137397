import { IAdminLoginResponse, IUser, IUserLoginResponse } from 'interface/user';
import api from './APIService';
import { handleApiError } from './errorHandlerService';

export const loginUser = async (
  telegramId: string,
  username: string,
  hash: string,
  referralCode?: string,
  photoUrl?: string,
): Promise<IUserLoginResponse | null> => {
  try {
    const response = await api.post<IUserLoginResponse>('/auth', {
      telegramId,
      username,
      hash,
      referralCode,
      photoUrl,
    });

    if (!response?.data) {
      return null;
    }

    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const loginAdmin = async (user: any): Promise<IAdminLoginResponse | null> => {
  try {
    const response = await api.post<IAdminLoginResponse>('/auth/admin', {
      user,
    });

    if (!response?.data) {
      return null;
    }

    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getUser = async (): Promise<IUser | null> => {
  try {
    const response = await api.get<IUser>('/users/me');
    if (!response?.data) {
      return null;
    }

    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const connectHotWallet = async (
  accountId: string,
  publicKey: string,
  message: string,
): Promise<number> => {
  try {
    const response = await api.post('/users/connect-hot-wallet', { accountId, publicKey, message });

    return response.status;
  } catch (error: any) {
    handleApiError(error);
    return error.response.status;
  }
};

export const disconnectHotWallet = async (): Promise<any> => {
  try {
    const response = await api.post('/users/disconnect-hot-wallet', {});
    return response.status;
  } catch (error: any) {
    handleApiError(error);
    return error.response.status;
  }
};
