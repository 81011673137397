export const LEVELS = {
  1: {
    level: 1,
    image: 'images/level/1.svg',
    name: 'Newbie',
    color: '#F38BAF',
  },
  2: {
    level: 2,
    image: 'images/level/1.svg',
    name: 'Moon Trader',
    color: '#F38BAF',
  },
  3: {
    level: 3,
    image: 'images/level/1.svg',
    name: 'HODLer',
    color: '#F38BAF',
  },
};
