import mainnet from './env-defaults/mainnet';
import testnet from './env-defaults/testnet';

export enum EEnvironment {
  TESTNET = 'testnet',
  MAINNET = 'mainnet',
}

export interface IConfig {
  serverUrl: string;
  botAppUrl: string;
  botName: string;
  GA: string;
  nftContractId: string;
}

const environments: { [key in EEnvironment]: IConfig } = {
  [EEnvironment.MAINNET]: mainnet,
  [EEnvironment.TESTNET]: testnet,
};

export const currentEnvironment: EEnvironment =
  (process.env.REACT_APP_ENV as EEnvironment) || EEnvironment.TESTNET;

export const { serverUrl, botAppUrl, botName, GA, nftContractId }: IConfig = {
  ...environments[currentEnvironment],
  GA: process.env.REACT_APP_GA || 'G-ZB5DDXNL9P',
};
