import Button from 'components/Button';
import useWeb3Store from 'store/web3.store';

const ConnectHotWallet = () => {
  const { modal } = useWeb3Store();

  const connectHotWallet = () => modal?.show();

  return (
    <div className="connect-hot-wallet">
      <img
        className="connect-hot-wallet-image"
        src="images/connect-hot-wallet.svg"
        alt="Connect Hot Wallet"
      />
      <div className="connect-hot-wallet-content">
        <h3>Connect HOT Wallet</h3>
        <p>
          Connect HOT wallet to receive <span>500 $PTCH</span> points and receive your{' '}
          <span>bonus for HODLing PitchTalk NFTs</span>
        </p>
        <Button
          className="connect-hot-wallet-button"
          color="grey"
          onClick={connectHotWallet}
          text={
            <>
              Connect
              <img src="images/hot.png" alt="Fire" style={{ width: '24px', height: '24px' }} />
              <span>HOT</span>
            </>
          }
        />
      </div>
    </div>
  );
};

export default ConnectHotWallet;
