import { IUser } from 'interface/user';
import api from './APIService';
import { IFarming } from 'interface/farming';
import { showSuccessToast } from 'utils/toastUtils';
import { handleApiError } from './errorHandlerService';

export const createFarming = async (): Promise<IFarming | null> => {
  try {
    const response = await api.post<IFarming>('/users/create-farming');
    if (!response?.data) {
      return null;
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const claimFarming = async (): Promise<IUser | null> => {
  try {
    const response = await api.post<IUser>('/users/claim-farming');
    if (!response?.data) {
      return null;
    }

    showSuccessToast('Claim success!');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getFarming = async (): Promise<IFarming | null> => {
  try {
    const response = await api.get<IFarming>('/farmings');
    if (!response?.data) {
      return null;
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};
