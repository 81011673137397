import { IFarming } from 'interface/farming';
import { create } from 'zustand';

interface IFarmingStore {
  farming: IFarming | null;
  isLoading: boolean;
  setFarming: (farming: IFarming | null) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const useFarmingStore = create<IFarmingStore>((set) => ({
  farming: null,
  isLoading: true,
  setFarming: (farming: IFarming | null) => set(() => ({ farming })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
}));

export default useFarmingStore;
